
  import Vue from 'vue'
  import { Component} from 'vue-property-decorator';

  @Component
  export default class Home extends Vue {
    // 初期データはインスタンスプロパティとして宣言できます
    message = 'Hello!'

    // コンポーネントメソッドはインスタンスメソッドとして宣言できます
    jobcanPc (): void {
      window.open("https://ssl.jobcan.jp/login/pc-employee-global?lang_code=ja")
    }
    jobcanMobile (): void {
      window.open("https://ssl.jobcan.jp/login/mb-employee?client_id=ejan&lang_code=ja")
    }

    gotoGitLab (): void {
      window.open("https://gitlab.ejan.work/", '_blank')
    }

    gotoRedmine (): void {
      window.open("https://redmine.ejan.work/", '_blank')
    }

    gotoWebMail (): void {
      window.open("https://acmail6.secure.ne.jp/am_bin/amlogin", '_blank')
    }

    gotoTeams (): void {
      window.open("https://www.microsoft.com/ja-jp/microsoft-teams/log-in", '_blank')
    }

    gotoMicrosoft365 (): void {
      window.open("https://www.office.com/", '_blank')
    }
  }

  // export default Vue.extend({
  //   name: 'HelloWorld',
  // })
