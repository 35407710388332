
import Vue from 'vue';
import EJanPortal from './components/EJanPortal.vue';

export default Vue.extend({
  name: 'App',
  components: {
    EJanPortal,
  },
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'ja')
  },
  data: () => ({
    //
  }),
});
